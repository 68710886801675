import { Grid, IconButton, TextField, Tooltip } from "@material-ui/core";
import { Add, Remove } from "@material-ui/icons";
import React, { useState } from "react";
import { Wrapper } from "../../../components/Control/Flexbox";
import Input from "../../../components/Control/Input";
import Modal from "../../../components/Control/Modal";
import PickItemMasterPart from "../PickItemMasterPart";

const RMHeadForm = ({ rmData, setRmData }) => {
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [render, setRender] = useState(false);
  const rmHeadHandler = (e) => {
    setRmData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };
  return (
    <section
      style={{
        display: "grid",
        gridTemplateColumns: "4fr 1fr",
        width: "100%",
      }}
    >
      <div>
        <Wrapper>
          <div
            style={{
              border: "1px solid black",
              padding: "0.7rem",
              width: "250px",
              marginLeft: "0.8rem",
            }}
          >
            <Grid container xs={12} direction="row" alignItems={"center"}>
              <Grid item xs={10}>
                <p
                  style={{
                    fontSize: "1rem",
                    margin: 0,
                  }}
                >
                  {rmData?.part_id?.item_description || rmData.item_desc}
                  {!rmData?.item_desc && "Pick Item Desc."}
                </p>
              </Grid>
              {rmData.part_id ? (
                <Grid item xs={2}>
                  <Tooltip title="remove" arrow>
                    <IconButton
                      size="small"
                      style={{
                        marginRight: "0.5rem",
                      }}
                      onClick={() => {
                        rmData.part_id = null;
                        rmData.item_desc = null;
                        rmData.item_code = null;
                        setRender((prev) => !prev);
                      }}
                    >
                      <Remove
                        style={{
                          fontSize: "1.4rem",
                          color: "#c92a2a",
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                </Grid>
              ) : (
                <Grid item xs={2}>
                  <Tooltip title="add" arrow>
                    <IconButton
                      size="small"
                      style={{
                        marginRight: "0.5rem",
                      }}
                      onClick={() => {
                        setIsOpenPopup(true);
                      }}
                    >
                      <Add
                        style={{
                          fontSize: "1.4rem",
                          color: "#003566",
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                </Grid>
              )}
            </Grid>
          </div>
          <Input
            className="input_field_width"
            type="text"
            disabled={true}
            name="item_code"
            value={rmData?.part_id?.item_no || rmData.item_code}
            onChange={rmHeadHandler}
          />
        </Wrapper>
        <Wrapper>
          <Input
            className="input_field_width"
            type="text"
            name="material"
            placeholder="Material"
            value={rmData.material}
            onChange={rmHeadHandler}
          />
        </Wrapper>
      </div>
      <div>
        <TextField
          type="text"
          variant="filled"
          name="remarks"
          multiline={true}
          minRows={6}
          maxRows={20}
          className="textarea-field"
          label="Notes"
          style={{
            paddingRight: "1.2rem",
            width: "100%",
          }}
          value={rmData.remarks}
          onChange={rmHeadHandler}
        />
      </div>
      <Modal
        openPopup={isOpenPopup}
        title="ITEM MASTER PARTS"
        closeModal={() => {
          setIsOpenPopup(false);
        }}
      >
        <PickItemMasterPart data={rmData} />
      </Modal>
    </section>
  );
};

export default RMHeadForm;

import "../style.css";
import RMPartListComponent from "./RMPartListComponent";
const PartList = () => {
    return (
        <>
            <main style={{ paddingBottom: "4rem" }}>
                <RMPartListComponent />
            </main>
        </>
    );
};

export default PartList;

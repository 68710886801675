import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
    Table,
    TableBody,
    TableCell,
    TableRow,
    TableHead,
} from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import BlockIcon from "@material-ui/icons/Block";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { DatePicker } from "@material-ui/pickers";
import { usePermission } from "../../components/usePermission";
import Loader from "../../components/Control/Loader";
import formatDate from "../../components/Control/formatDate";
import useConfirm from "../../components/Control/useConfirm";
import { useQuery } from "../../components/Control/useQuery";

const useStyles = makeStyles({
    table: {
        width: "100%",
        marginTop: "1.5rem",
        backgroundColor: "white",
    },
});
const Editable = ({ rmId, id, deleteRm }) => {
    const permissions = usePermission(7);
    return (
        <TableCell align="center">
            <Tooltip title="PIR" arrow>
                {permissions?.includes("view") ? (
                    <Link to={`/incomingbreakload/tab/${rmId}/${id}?isView=true`}>
                        <IconButton size="small">
                            <VisibilityIcon
                                style={{ fontSize: "1.4rem", color: "#003566" }}
                            />
                        </IconButton>
                    </Link>
                ) : (
                    <BlockIcon style={{ color: "#c92a2a" }} />
                )}
            </Tooltip>

            {permissions?.includes("edit") && (
                <Tooltip title="Edit" arrow>
                    <Link to={`/incomingbreakload/tab/${rmId}/${id}?isView=false`}>
                        <IconButton size="small" style={{ marginLeft: "0.5rem" }}>
                            <EditIcon style={{ fontSize: "1.4rem", color: "#003566" }} />
                        </IconButton>
                    </Link>
                </Tooltip>
            )}
            {permissions?.includes("delete") && (
                <Tooltip title="Delete" arrow>
                    <IconButton
                        size="small"
                        style={{ marginLeft: "0.5rem" }}
                        onClick={() => deleteRm(id)}
                    >
                        <DeleteIcon
                            style={{
                                fontSize: "1.4rem",
                                color: "red",
                            }}
                        />
                    </IconButton>
                </Tooltip>
            )}
        </TableCell>
    );
};
const NotEditable = ({ rmId, id }) => {
    const query = useQuery();
    const permissions = usePermission(7);

    return (
        <TableCell align="center">
            <Tooltip title="PIR" arrow>
                {permissions?.includes("view") ? (
                    <Link to={`/incomingbreakload/tab/${rmId}/${id}?isView=false`}>
                        <IconButton size="small">
                            <VisibilityIcon
                                style={{ fontSize: "1.4rem", color: "#003566" }}
                            />
                        </IconButton>
                    </Link>
                ) : (
                    <BlockIcon style={{ color: "#c92a2a" }} />
                )}
            </Tooltip>
            <Tooltip title="Block" arrow>
                <IconButton size="small" style={{ marginLeft: "0.5rem" }}>
                    <BlockIcon
                        style={{
                            fontSize: "1.4rem",
                            color: "red",
                        }}
                    />
                </IconButton>
            </Tooltip>
            <Tooltip title="Block" arrow>
                <IconButton size="small" style={{ marginLeft: "0.5rem" }}>
                    <BlockIcon
                        style={{
                            fontSize: "1.4rem",
                            color: "red",
                        }}
                    />
                </IconButton>
            </Tooltip>
        </TableCell>
    );
};

const superviserStatus = (
    item,
    isSubmit,
    adminRequestStatus,
    deleteRm,
    DialogDelete,
) => {
    //notsubmitted
    if (!isSubmit) {
        return (
            <>
                <TableCell
                    align="center"
                    style={{
                        backgroundColor: "#1864ab",
                        color: "white",
                    }}
                >
                    <span>SAVED</span>
                </TableCell>
                <Editable id={item._id} rmId={item.rm_id} deleteRm={deleteRm} />
                <DialogDelete isSubmitContent={false} />
            </>
        );
    }

    if (adminRequestStatus === "pending" && isSubmit) {
        return (
            <>
                <TableCell
                    align="center"
                    style={{
                        backgroundColor: "#fdd835",
                        color: "black",
                        maxWidth: "200px",
                    }}
                >
                    <span
                        style={{
                            display: "flex",
                            width: "100%",
                            alignItems: "Center",
                            justifyContent: "center",
                            gap: "1rem",
                        }}
                    >
                        PENDING FOR APPROVAL
                    </span>
                </TableCell>
                <NotEditable item={item} id={item._id} rmId={item.rm_id} />
            </>
        );
    }
    if (adminRequestStatus === "accepted" && isSubmit) {
        return (
            <>
                <TableCell
                    align="center"
                    style={{
                        backgroundColor: "#2b8a3e",
                        color: "#fff",
                    }}
                >
                    <span>APPROVED</span>
                </TableCell>
                <NotEditable item={item} id={item._id} rmId={item.rm_id} />
            </>
        );
    }

    if (adminRequestStatus === "rejected" && isSubmit) {
        return (
            <>
                <TableCell
                    align="center"
                    style={{
                        backgroundColor: "#f03e3e",
                        color: "#fff",
                    }}
                >
                    <span>SENT BACK FOR RECHECKING</span>
                </TableCell>
                <Editable deleteRm={deleteRm} id={item._id} rmId={item.rm_id} />
            </>
        );
    }
};

function SupervisorRm({ tabValue }) {
    const classes = useStyles();
    const [rmListData, setRmListData] = useState(null);
    const [render, setRender] = useState(false);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [isLoading, setIsLoading] = useState(false);

    const [DialogDelete, confirmDelete] = useConfirm(
        "DELETE",
        "Are you sure you want to delete this Document?",
    );

    useEffect(() => {
        if (selectedDate) {
            setIsLoading(true);
            const status = tabValue === 0 ? "pending" : "accepted";
            axios
                .get(
                    `/api/getAllMonthlyRmList/supervisor/${selectedDate}?status=${status}`,
                )
                .then((res) => {
                    setRmListData(
                        res.data.sort((a, b) =>
                            a.inspection_date.localeCompare(b.inspection_date),
                        ),
                    );
                    setIsLoading(false);
                })
                .catch((err) => {
                    console.log(err);
                    setIsLoading(false);
                });
        }
    }, [render, selectedDate, tabValue]);

    const deleteDocument = async (id) => {
        let isDeleted = await confirmDelete();
        if (isDeleted) {
            axios
                .delete(`/api/remove/inspection_breakload/${id}`)
                .then(() => {
                    setRender((prev) => !prev);
                })
                .catch((err) => {
                    console.log(err);
                });
            return;
        }
    };

    if (isLoading) {
        return <Loader />;
    }

    return (
        <div style={{ paddingBottom: "3rem" }}>
            <div style={{ marginTop: "2rem", marginLeft: "1rem" }}>
                <DatePicker
                    views={["month", "year"]}
                    label="Month & Year"
                    className="date-field"
                    inputVariant="filled"
                    value={selectedDate}
                    onChange={setSelectedDate}
                />
            </div>
            <Table className={classes.table} size="small">
                <TableHead>
                    <TableRow style={{ padding: "6px" }}>
                        <TableCell style={{ fontWeight: "bold" }} align="center">
                            #
                        </TableCell>
                        <TableCell style={{ fontWeight: "600" }} align="center">
                            INSPECTION DATE
                        </TableCell>
                        <TableCell style={{ fontWeight: "600" }} align="center">
                            ITEM CODE
                        </TableCell>
                        <TableCell style={{ fontWeight: "600" }} align="center">
                            ITEM DESCRIPTION
                        </TableCell>
                        <TableCell style={{ fontWeight: "bold" }} align="center">
                            MRM NO.
                        </TableCell>
                        <TableCell style={{ fontWeight: "bold" }} align="center">
                            STATUS
                        </TableCell>
                        <TableCell style={{ fontWeight: "bold" }} align="center">
                            ACTION
                        </TableCell>
                    </TableRow>
                </TableHead>
                {rmListData && (
                    <TableBody>
                        {rmListData.map((item, index) => (
                            <React.Fragment key={item._id}>
                                <TableRow className={classes.rootRow}>
                                    <TableCell align="center">{index + 1}</TableCell>
                                    <TableCell align="center">
                                        {formatDate(item.inspection_date)}
                                    </TableCell>
                                    <TableCell align="center">{item.item_code}</TableCell>
                                    <TableCell align="center">{item.item_desc}</TableCell>
                                    <TableCell align="center">{item.mrm_no}</TableCell>
                                    {superviserStatus(
                                        item,
                                        item.isSubmitted,
                                        item.is_admin_request,
                                        deleteDocument,
                                        DialogDelete,
                                    )}
                                </TableRow>
                            </React.Fragment>
                        ))}
                    </TableBody>
                )}
            </Table>
        </div>
    );
}

export default SupervisorRm;

import { Link, useHistory } from "react-router-dom";
import VisibilityIcon from "@material-ui/icons/Visibility";
import CreateIcon from "@material-ui/icons/Create";
import { useAuth } from "../../context/GlobalContext";
import useConfirm from "../Control/useConfirm";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import NoteIcon from "@material-ui/icons/Note";
import homePage from "./HomePage.module.css";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import BallotIcon from "@material-ui/icons/Ballot";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import TuneIcon from "@material-ui/icons/Tune";
import HomeWorkIcon from "@material-ui/icons/HomeWork";
import LiveHelpIcon from "@material-ui/icons/LiveHelp";
import {
    Assessment,
    CloudUpload,
    DesktopMac,
    DomainDisabled,
    ListAlt,
    LocationOn,
    People,
    Receipt,
} from "@material-ui/icons";
import ListIcon from "@material-ui/icons/List";

import LocalLibraryIcon from "@material-ui/icons/LocalLibrary";

const Admin = () => {
    const { state, logout } = useAuth();
    const access = state.user?.access || [];
    const history = useHistory();
    const [Dialog, confirmLogoutUser] = useConfirm(
        "LOG OUT",
        "Are you sure you want to log out?",
    );
    const logoutUser = async () => {
        const ans = await confirmLogoutUser();
        if (ans) {
            logout();
            history.push("/");
        }
    };
    if (!state.user) {
        logout();
        history.push("/");
    }

    if (state.user?.userType == 2) {
        return (
            <>
                <main className={`${homePage.container} ${homePage.main}`}>
                    {access.some((obj) => obj.id === 1) && (
                        <div>
                            <Link to="/part_list" className="panel">
                                <CreateIcon style={{ fontSize: "3rem", color: "maroon" }} />
                            </Link>{" "}
                            <p className="card-btn-para">
                                Add <span className="card-btn-span">Part</span>
                            </p>
                        </div>
                    )}

                    {access.some((obj) => obj.id === 2) && (
                        <div>
                            <Link to="/annexure_management" className="panel">
                                <BallotIcon style={{ fontSize: "3rem", color: "maroon" }} />
                            </Link>{" "}
                            <p className="card-btn-para">
                                ANNEXURE <span className="card-btn-span">MANAGEMENT</span>
                            </p>
                        </div>
                    )}
                    {access.some((obj) => obj.id === 3) && (
                        <div>
                            <Link to="/viewPartTable" className="panel">
                                <VisibilityIcon
                                    style={{ fontSize: "3rem", color: "#054a29" }}
                                />
                            </Link>{" "}
                            <p className="card-btn-para">
                                View <span className="card-btn-span">Documents</span>
                            </p>
                        </div>
                    )}
                    {access.some((obj) => obj.id === 3) && (
                        <div>
                            <Link to="/viewAllPirTableList" className="panel">
                                <ListAlt style={{ fontSize: "3rem", color: "#003566" }} />
                            </Link>
                            <p className="card-btn-para">
                                PENDING INSPECTION
                                <span className="card-btn-span"> & SETUP REPORTS</span>
                            </p>
                        </div>
                    )}
                    {access.some((obj) => obj.id === 4) && (
                        <div>
                            <a
                                href="https://slf.secure.aqua.mushinlabs.com/common/annualPmPlanUtilityTiles"
                                rel="noopener noreferrer"
                                className="panel"
                                style={{ fontSize: "3rem", color: "#003566" }}
                            >
                                <Receipt style={{ fontSize: "3rem", color: "#003566" }} />
                            </a>
                            <p className="card-btn-para">
                                Annual Process &
                                <span className="card-btn-span">Product Audit</span>
                            </p>
                        </div>
                    )}
                    {access.some((obj) => obj.id === 5) && (
                        <div>
                            <Link to="/fmeamanagement" className="panel">
                                <HomeWorkIcon style={{ fontSize: "3rem", color: "#003566" }} />
                            </Link>
                            <p className="card-btn-para">
                                FMEA
                                <span className="card-btn-span">MANAGEMENT</span>
                            </p>
                        </div>
                    )}

                    {access.some((obj) => obj.id === 6) && (
                        <div>
                            <Link to="/masterFormatDocumentTile" className="panel">
                                <NoteIcon style={{ fontSize: "3rem", color: "#003566" }} />
                            </Link>
                            <p className="card-btn-para">
                                Master
                                <span className="card-btn-span">Formats</span>
                            </p>
                        </div>
                    )}
                    {access.some((obj) => obj.id === 7) && (
                        <div>
                            <Link to="/rmpartlist" className="panel">
                                <AccountTreeIcon
                                    style={{ fontSize: "3rem", color: "#003566" }}
                                />
                            </Link>
                            <p className="card-btn-para">
                                Raw Material
                                <span className="card-btn-span">Receiving</span>
                            </p>
                        </div>
                    )}
                    {access.some((obj) => obj.id === 7) && (
                        <div>
                            <Link to="/viewAllRmTableList" className="panel">
                                <ListAlt style={{ fontSize: "3rem", color: "#003566" }} />
                            </Link>
                            <p className="card-btn-para">
                                RM
                                <span className="card-btn-span">REPORTS</span>
                            </p>
                        </div>
                    )}
                    {access.some((obj) => obj.id === 8) && (
                        <div>
                            <Link to="/listMasterList" className="panel">
                                <FormatListBulletedIcon
                                    style={{ fontSize: "3rem", color: "black" }}
                                />
                            </Link>
                            <p className="card-btn-para">
                                Item<span className="card-btn-span">Master</span>
                            </p>
                        </div>
                    )}
                    {access.some((obj) => obj.id === 9) && (
                        <div>
                            <a
                                href="https://slf.secure.aqua.mushinlabs.com/common/instrumentMasterlist"
                                rel="noopener noreferrer"
                                className="panel"
                                style={{ fontSize: "3rem", color: "#003566" }}
                            >
                                <TuneIcon style={{ fontSize: "3rem", color: "#003566" }} />
                            </a>
                            <p className="card-btn-para">
                                Master List of
                                <span className="card-btn-span">Instruments</span>
                            </p>
                        </div>
                    )}
                    {access.some((obj) => obj.id === 10) && (
                        <div>
                            <a
                                href="https://slf.secure.aqua.mushinlabs.com/common/obsoleteInstrumentMasterlist"
                                rel="noopener noreferrer"
                                className="panel"
                                style={{ fontSize: "3rem", color: "#003566" }}
                            >
                                <TuneIcon style={{ fontSize: "3rem", color: "#003566" }} />
                            </a>
                            <p className="card-btn-para">
                                Master List of
                                <span className="card-btn-span">Obsolete Instruments</span>
                            </p>
                        </div>
                    )}
                    {access.some((obj) => obj.id === 11) && (
                        <div>
                            <a
                                href="https://slf.secure.aqua.mushinlabs.com/common/tilesCallibrationplan"
                                rel="noopener noreferrer"
                                className="panel"
                                style={{ fontSize: "3rem", color: "#003566" }}
                            >
                                <LocalLibraryIcon
                                    style={{ fontSize: "3rem", color: "#003566" }}
                                />
                            </a>
                            <p className="card-btn-para">
                                CALLIBRATION
                                <span className="card-btn-span">PLAN</span>
                            </p>
                        </div>
                    )}
                    {access.some((obj) => obj.id === 12) && (
                        <div>
                            <a
                                href="https://slf.secure.aqua.mushinlabs.com/common/masterListLocationList"
                                rel="noopener noreferrer"
                                className="panel"
                                style={{ fontSize: "3rem", color: "#003566" }}
                            >
                                <LocationOn style={{ fontSize: "3rem", color: "#003566" }} />
                            </a>
                            <p className="card-btn-para">
                                Manage
                                <span className="card-btn-span">Location</span>
                            </p>
                        </div>
                    )}
                    {access.some((obj) => obj.id === 13) && (
                        <div>
                            <a
                                href="https://slf.secure.aqua.mushinlabs.com/common/masterListApprovedExternalProvederList"
                                rel="noopener noreferrer"
                                className="panel"
                                style={{ fontSize: "3rem", color: "#003566" }}
                            >
                                <ListIcon style={{ fontSize: "3rem", color: "#003566" }} />
                            </a>
                            <p className="card-btn-para">
                                APPROVED EXTERNAL
                                <span className="card-btn-span">PROVIDERS</span>
                            </p>
                        </div>
                    )}
                    {access.some((obj) => obj.id === 14) && (
                        <div>
                            <a
                                href="https://slf.secure.aqua.mushinlabs.com/common/spcPlanTiles"
                                rel="noopener noreferrer"
                                className="panel"
                            >
                                <Assessment style={{ fontSize: "3rem", color: "#003566" }} />
                            </a>
                            <p className="card-btn-para">
                                SPC
                                <span className="card-btn-span">REPORT</span>
                            </p>
                        </div>
                    )}
                    {access.some((obj) => obj.id === 15) && (
                        <div>
                            <a
                                href="https://slf.secure.aqua.mushinlabs.com/common/tilesMsaplan"
                                rel="noopener noreferrer"
                                className="panel"
                            >
                                <Assessment style={{ fontSize: "3rem", color: "#003566" }} />
                            </a>
                            <p className="card-btn-para">
                                MSA
                                <span className="card-btn-span">REPORT</span>
                            </p>
                        </div>
                    )}
                    {access.some((obj) => obj.id === 16) && (
                        <div>
                            <a
                                href="https://slf.secure.aqua.mushinlabs.com/common/abnormalityHandling"
                                rel="noopener noreferrer"
                                className="panel"
                                style={{ fontSize: "3rem", color: "#003566" }}
                            >
                                <Assessment style={{ fontSize: "3rem", color: "#003566" }} />
                            </a>
                            <p className="card-btn-para">
                                ABNORMALITY HANDLING
                                <span className="card-btn-span">REPORT</span>
                            </p>
                        </div>
                    )}
                    {access.some((obj) => obj.id === 17) && (
                        <div>
                            <a
                                href="https://slf.secure.aqua.mushinlabs.com/common/qualityDepartmentUpload/?documentType=qualityDmsUpload&heading=QUALITY DMS UPLOAD"
                                rel="noopener noreferrer"
                                className="panel"
                                style={{ fontSize: "3rem", color: "#003566" }}
                            >
                                <CloudUpload style={{ fontSize: "3rem", color: "#003566" }} />
                            </a>
                            <p className="card-btn-para">
                                QUALITY DEPARTMENT
                                <span className="card-btn-span">UPLOAD</span>
                            </p>
                        </div>
                    )}
                    {access.some((obj) => obj.id === 18) && (
                        <div>
                            <Link to="/deviceList" className="panel">
                                <DesktopMac style={{ fontSize: "3rem", color: "maroon" }} />
                            </Link>
                            <p className="card-btn-para">
                                Master List of <span className="card-btn-span">Device</span>
                            </p>
                        </div>
                    )}

                    {access.some((obj) => obj.id === 19) && (
                        <div>
                            <a
                                href="https://slf.secure.aqua.mushinlabs.com/common/mushinAquaMastersTiles"
                                rel="noopener noreferrer"
                                className="panel"
                                style={{ fontSize: "3rem", color: "#003566" }}
                            >
                                <FormatListBulletedIcon
                                    style={{ fontSize: "3rem", color: "maroon" }}
                                />
                            </a>
                            <p className="card-btn-para">
                                MUSHIN AQUA <span className="card-btn-span">MASTERS</span>
                            </p>
                        </div>
                    )}
                    {access.some((obj) => obj.id === 20) && (
                        <div>
                            <a
                                href="https://slf.secure.aqua.mushinlabs.com/common/mushinHelpDesk"
                                rel="noopener noreferrer"
                                className="panel"
                                style={{ fontSize: "3rem", color: "#003566" }}
                            >
                                <LiveHelpIcon style={{ fontSize: "3rem", color: "#003566" }} />
                            </a>
                            <p className="card-btn-para">
                                MUSHIN HELP
                                <span className="card-btn-span">DESK</span>
                            </p>
                        </div>
                    )}
                    {access.some((obj) => obj.id === 31) && (
                        <div>
                            <a
                                href="https://slf.secure.aqua.mushinlabs.com/admin/clause5"
                                rel="noopener noreferrer"
                                className="panel"
                                style={{ fontSize: "3rem", color: "#003566" }}
                            >
                                <People style={{ fontSize: "3rem", color: "#054a29" }} />
                            </a>
                            <p className="card-btn-para">
                                HR
                                <span className="card-btn-span">DEPARTMENT</span>
                            </p>
                        </div>
                    )}
                    {access.some((obj) => obj.id === 32) && (
                        <div>
                            <a
                                href="https://slf.secure.aqua.mushinlabs.com/common/productionAndMaintenaceHome"
                                rel="noopener noreferrer"
                                className="panel"
                                style={{ fontSize: "3rem", color: "#003566" }}
                            >
                                <DomainDisabled
                                    style={{ fontSize: "3rem", color: "#003566" }}
                                />
                            </a>
                            <p className="card-btn-para">
                                PRODUCTION & MAINTENANCE
                                <span className="card-btn-span">DEPARTMENT</span>
                            </p>
                        </div>
                    )}
                    <div>
                        <div
                            style={{ cursor: "pointer" }}
                            to="/add_data"
                            className="panel"
                            onClick={logoutUser}
                        >
                            <ExitToAppIcon style={{ fontSize: "3rem", color: "maroon" }} />
                        </div>{" "}
                        <p className="card-btn-para">
                            Logout <span className="card-btn-span">Aqua</span>
                        </p>
                    </div>
                </main>

                <Dialog isSubmitContent={false} />
            </>
        );
    } else {
        return (
            <div>
                <h1>Page Not Found</h1>
            </div>
        );
    }
};

export default Admin;

import React, { useEffect, useState } from "react";
import { images, textInputs } from "../../constants/constants";
import { CenteredLogoImg } from "../../golbalStyles/global.styled";
import Styled from "styled-components";
import { Wrapper } from "../../components/Control/Flexbox";
import { useParams } from "react-router-dom";
import axios from "axios";
const Table = Styled.table`
	
    width:100%;
    th{
        font-size: 12px;
		    font-family:"Roboto",sans-serif;
        border: 1px solid black;
        padding: 0.4rem;
        p{
          margin:0;
        }
    }
    td{
        font-size: 12px;
        border: 1px solid black;
        padding: 0.3rem;
        p{
          margin:0;
        }
    }
    span{
      font-weight:500;
    }
    .heading{
        th{
            text-align:center;
        }
        td{
            text-align:center;
        }
    }

    .input {
        outline: none;
        width: 100%;
        border: none;
        padding-left: 4px;
        height: 40px;
    }

`;

const ViewRMDiagram = () => {
  const [data, setData] = useState({ specs: [] });
  const { id } = useParams();
  useEffect(() => {
    axios
      .get(`/api/getById/rm/${id}`)
      .then((res) => {
        if (res.data) {
          setData(res.data);
        }
      })
      .catch((err) => console.error(err));
  }, [id]);
  return (
    <main style={{ margin: "1rem", paddingBottom: "3rem", overflow: "scroll" }}>
      <Table>
        <tbody>
          <tr data-height="80">
            <th
              colSpan="9"
              align="left"
              rowSpan={4}
              style={{
                fontSize: "1.6rem",
                textAlign: "center",
              }}
              data-a-v="middle"
            >
              <CenteredLogoImg>
                <img
                  src={images.CompanyLogo}
                  alt={textInputs.companyName}
                  height="46"
                  width="120"
                />
                <span>{textInputs.companyName}</span>
              </CenteredLogoImg>
            </th>
            <th data-f-sz="10" colSpan={2}>
              FORMAT NO: F QAD 18
            </th>
          </tr>
          <tr>
            <th data-f-sz="10" colSpan={2}>
              ISSUE DATE: 01/04/2017
            </th>
          </tr>
          <tr>
            <th data-f-sz="10" colSpan={2}>
              REVISION NO: 04
            </th>
          </tr>
          <tr>
            <th data-f-sz="10" colSpan={2}>
              REVISION DATE: 09/07/2022
            </th>
          </tr>
          <tr>
            <th
              colSpan={11}
              align="center"
              style={{
                fontSize: "1.2rem",
                fontWeight: 600,
                backgroundColor: "#1d1d1d",
                color: "white",
                border: "1px solid black",
                textAlign: "center",
              }}
              data-font-sz="18"
              data-a-h="center"
              data-f-bold="true"
              data-fill-color="#1d1d1d"
              data-f-color="FFFFFF"
            >
              INCOMING MATERIAL INSPECTION REPORT
            </th>
          </tr>
          <tr>
            <td colSpan={3} data-f-sz="10">
              GRPO NO.:{" "}
            </td>
            <td colSpan={3} data-f-sz="10">
              DATE:{" "}
            </td>
            <td colSpan={5} data-f-sz="10">
              ITEM CODE: {data.part_id?.item_no || data.item_code}
            </td>
          </tr>
          <tr>
            <td colSpan={6} data-f-sz="10">
              ITEM DESCRIPTION:{" "}
              {data.part_id?.item_description || data.item_desc}
            </td>
            <td colSpan={5} data-f-sz="10">
              EXTERNAL PROVIDER:{" "}
            </td>
          </tr>
          <tr>
            <td colSpan={6} data-f-sz="10">
              LOT QUANTITY:{" "}
            </td>
            <td colSpan={5} data-f-sz="10">
              LOT CODE:{" "}
            </td>
          </tr>
          <tr>
            <td colSpan={6} data-f-sz="10">
              MATERIAL TYPE:{" "}
            </td>
            <td colSpan={5} data-f-sz="10">
              <Wrapper style={{ gap: "1rem" }}>INSPECTION DATE: </Wrapper>
            </td>
          </tr>

          <tr className="heading">
            {[
              "S.NO.",
              "CHARACTERISTICS",
              "UNIT",
              "METHOD",
              "SAMPLE TAKEN",
              "SPECIFICATION / TOLERANCE",
            ].map((heading) => (
              <th rowSpan={3}>{heading}</th>
            ))}
            <th colSpan={3}>QUANTITY</th>
            <th>RESULTS</th>
            <th rowSpan={3}>REMARKS</th>
          </tr>
          <tr className="heading">
            <th colSpan={2}>WITH RANGE</th>
            <th rowSpan={2}>BEYOND</th>
            <th rowSpan={2}>(OK/UD/REJ.)</th>
          </tr>
          <tr className="heading">
            <th>MIN</th>
            <th>MAX</th>
          </tr>
          {data.specs.map((incoming, index) => (
            <tr className="heading">
              <td>{index + 1}</td>
              <td>{incoming.characteristic}</td>
              <td>{incoming.unit}</td>
              <td>{incoming.method}</td>
              <td>{incoming.sample_taken}</td>
              <td>{incoming.control_product}</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          ))}
          <tr>
            <td colSpan={2}>DISPOSITION WAY: {data.disposition_way}</td>
            <td colSpan={3}>REJECTED: {data.rejected}</td>
            <td colSpan={4}>REWORK: {data.rework}</td>
            <td colSpan={2}>ACCEPTED UNDER DEVIATION: {data.deviation}</td>
          </tr>
          <tr>
            <td colSpan={2}></td>
            <td colSpan={3}></td>
            <td colSpan={4}></td>
            <td colSpan={2}></td>
          </tr>
          <tr>
            <td colSpan={11}>REMARKS: {data.remarks}</td>
          </tr>
          <tr>
            <th colSpan={11}>Prepared By:</th>
          </tr>
          <tr>
            <td colSpan={11}>Signature:</td>
          </tr>
          <tr>
            <td colSpan={11}>Name:</td>
          </tr>
          <tr>
            <td colSpan={11}>Designation:</td>
          </tr>
        </tbody>
      </Table>
    </main>
  );
};

export default ViewRMDiagram;

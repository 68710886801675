import { Paper, Tab, Tabs } from "@material-ui/core";
import { useState } from "react";
import { useAuth } from "../../context/GlobalContext";
import AdminRm from "./AdminRm";
import SupervisorRm from "./SupervisorRm";

function RmTableList() {
    const { state } = useAuth();
    const [tabValue, setTabValue] = useState(0);
    return (
        <>
            {/* <div */}
            {/*   className="divflex" */}
            {/*   style={{ */}
            {/*     fontSize: "24px", */}
            {/*   }} */}
            {/* > */}
            {/*   RM INCOMING / BREAKLOAD */}
            {/* </div> */}
            <Paper elevation={2}>
                <Tabs
                    value={tabValue}
                    onChange={(e, newValue) => setTabValue(+newValue)}
                    indicatorColor="primary"
                    variant="fullWidth"
                    textColor="primary"
                >
                    <Tab label="PENDING RM REPORTS" />
                    <Tab label="APPROVED RM REPORTS" />
                </Tabs>
            </Paper>
            {state.user.userType === 2 ? (
                <AdminRm {...{ tabValue }} />
            ) : (
                <SupervisorRm {...{ tabValue }} />
            )}
        </>
    );
}

export default RmTableList;
